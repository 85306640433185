import { FC } from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size } from 'src/utils/constants'

type Props = {
  text: string
}

export const Text: FC<Props> = ({ text, ...props }) => {
  return (
    <p css={rootStyle} {...props}>
      {text}
    </p>
  )
}

const rootStyle = css`
  &:not(:last-child) {
    margin-bottom: ${size.gutter.s}rem;
  }
`
