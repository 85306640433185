import { FC } from 'react'
import { Layout } from 'src/components/site/Layout'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { Paragraph } from 'src/components/parts/Paragraph'
import { Breadcrumb } from 'src/components/groups/Breadcrumb'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { PageHeading } from 'src/components/parts/PageHeading'
import { Text } from 'src/components/parts/Text'

const PrivacyPolicyPage: FC = () => {
  const breadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: 'PRIVACY POLICY',
      path: '/privacy-policy/',
    },
  ]

  return (
    <Layout
      title="PRIVACY POLICY"
      description="KOKONIARUKOTO.の個人情報の取り扱いについて、プライバシーポリシーとして記載しています。"
      ldType="WebPage"
    >
      <Section>
        <Container>
          <PageHeading heading="PRIVACY POLICY" />
        </Container>
      </Section>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Section>
        <Container small>
          <SectionHeading heading="個人情報の利用目的について" />
          <Paragraph>
            <Text text="お問い合わせやご相談から得た個人、企業の情報は、連絡手段やご依頼内容以外の目的で使用することはありません。" />
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container small>
          <SectionHeading heading="個人情報の開示について" />
          <Paragraph>
            <Text text="個人情報は適切に管理、または、破棄し、第三者へ開示されることはありませんが、以下に該当する場合は、適切な方法で開示します。" />
          </Paragraph>
          <Paragraph>
            <Text text="・ご本人の承諾がある場合" />
            <Text text="・正当な代理人からの請求があった場合" />
            <Text text="・その他、法令に基づき開示が必要な場合" />
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container small>
          <SectionHeading heading="アクセス解析について" />
          <Paragraph>
            <Text text="当サイトは、Googleが提供するGoogleアナリティクスを利用しています。" />
            <Text text="Googleアナリティクスは、トラフィックデータ収集するため、Cookieを使用しています。" />
            <Text text="個人を特定することはできませんが、Cookieを無効にすることでトラフィックデータも収集されないように設定できますので、ブラウザの設定をご確認下さい。" />
          </Paragraph>
        </Container>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicyPage
